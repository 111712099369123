import React from 'react';
import reactHtmlParser from 'react-html-parser';
import { isEmpty } from 'lodash';
import Layout from './layout';
import Quote from './quote';
import InfoBox from './info-box';
import './json-page.css';

const JsonPage = ({ page }) => {
  const { title, subTitle, body, id } = page;
  const sectionNames = Object.keys(body);

  const renderSectionItem = (item, section, id) => {
    const { name, link, description, logo, status, date, thumbnail } = item;
    return (
      <div
        className={`${section}-item section-item`}
        key={`${section}-${name}-${id}`}
      >
        <h4 className={`${section}-item-title section-item-title`}>
          {logo && !isEmpty(logo) && (
            <img
              className={`${section}-item-title-logo section-item-title-logo`}
              src={`./logos/${logo}`}
              alt={logo}
            />
          )}
          <a
            className={`${section}-item-title-link section-item-title-link`}
            href={link}
          >
            {name}
          </a>
        </h4>

        <div className={`${section}-item-description section-item-description`}>
          {thumbnail && !isEmpty(thumbnail) && (
            <img
              className={`${section}-item-title-thumbnail section-item-title-thumbnail`}
              src={`./images/${thumbnail}`}
              alt={thumbnail}
            />
          )}
          <div className={`${section}-item-copy section-item-copy`}>
            {reactHtmlParser(description)}
          </div>
        </div>
        <div
          className={`${section}-item-metadata-container section-item-metadata-container`}
        >
          <p className={`${section}-item-status section-item-status`}>
            {status} &nbsp;
          </p>
          {date && !isEmpty(date) && (
            <p className={`${section}-item-date section-item-date`}>{date}</p>
          )}
        </div>
      </div>
    );
  };

  const sectionLayouts = sectionNames.map((section, index) => {
    const isEven = (num) => num % 2 === 0;
    let sectionItems = [];
    Object.values(body[section]).forEach((item) => {
      const renderedItem = renderSectionItem(item, section, id);
      sectionItems.push(renderedItem);
    });

    return (
      <div className={`${section} section`} key={`${section}-${index}`}>
        {!isEven(index) && (
          <div className="halfcircle-container">
            <div className="halfcircle left">
              <h3 className={`${section}-title section-title`}>{section}</h3>
            </div>
          </div>
        )}

        <div className={`${section}-items-container section-items-container`}>
          {sectionItems}
        </div>
        {isEven(index) && (
          <div className="halfcircle-container">
            <div className="halfcircle right">
              <h3 className={`${section}-title section-title`}>{section}</h3>
            </div>
          </div>
        )}
      </div>
    );
  });

  return (
    <Layout>
      <h1>{title}</h1>
      <h2>{subTitle}</h2>
      <Quote />
      <InfoBox />
      <div className="json-page-body">{sectionLayouts}</div>
    </Layout>
  );
};

export default JsonPage;
