import React from 'react';
import useDarkMode from 'use-dark-mode';

const QuoteSvg = ({
  height = 30,
  width = 24,
  fill = '#bf002480',
  className = '',
}) => {
  const { value: isDark } = useDarkMode(false);
  fill = isDark ? '#bf002480' : '#bf00245f';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1792 1792"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill={fill} stroke="none">
        <path d="M832 960v384q0 80-56 136t-136 56h-384q-80 0-136-56t-56-136v-704q0-104 40.5-198.5t109.5-163.5 163.5-109.5 198.5-40.5h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136zm896 0v384q0 80-56 136t-136 56h-384q-80 0-136-56t-56-136v-704q0-104 40.5-198.5t109.5-163.5 163.5-109.5 198.5-40.5h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136z" />
      </g>
    </svg>
  );
};

export default QuoteSvg;
