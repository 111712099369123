import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const data = useStaticQuery(graphql`
    {
      allQuote {
        edges {
          node {
            id
            quote
            attribution
          }
        }
      }
    }
  `);

  return data.allQuote.edges.map((item) => item.node);
};
