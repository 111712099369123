import React from 'react';
import { DateTime } from 'luxon';
import { getMoonPhase } from './get-moon-phase';
import useCurrentBuildDate from './use-current-build-date';
import './info-box.css';

const InfoBox = () => {
  const currentLocalTime = DateTime.local();
  const { currentBuildDate } = useCurrentBuildDate();
  const moonphase = getMoonPhase(
    currentLocalTime.year,
    currentLocalTime.month,
    currentLocalTime.day,
  );

  return (
    <div className="info-container">
      <p>
        The Current time is{' '}
        {currentLocalTime.toLocaleString(DateTime.DATETIME_FULL)}. <br />
        Tonight features the {moonphase.name}.<br />
        This website was last updated on {currentBuildDate.currentDate}
      </p>
      {/* <p>🌓 The new moon was 6 days ago. Internet time is @371.28 beats.</p> */}
      {/* <p>Current Local Time: {currentLocalTime}</p> */}
    </div>
  );
};

export default InfoBox;
