import React from 'react';
import reactHtmlParser from 'react-html-parser';
import { getRandomInt } from '../../utils/randomInt';
import useQuote from './use-quote';
import QuoteSvg from './quoteSvg';

import './quote.css';

const Quote = () => {
  const quotes = useQuote();
  if (!quotes || quotes.length === 0) {
    return;
  }
  const quoteToRender = getRandomInt(quotes.length);

  const { quote, attribution } = quotes[quoteToRender];
  return (
    <div className="quote-container">
      <QuoteSvg height={100} width={100} className={'quote-svg'} />
      <p className="quote">{reactHtmlParser(quote)}</p>
      <p className="attribution">
        {' '}
        {reactHtmlParser('&mdash;')} {attribution}
      </p>
    </div>
  );
};

export default Quote;
