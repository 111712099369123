import React from 'react';
import { Link } from 'gatsby';
import useSiteMetadata from 'gatsby-theme-json-pages/src/components/use-site-metadata';
import DarkModeToggle from 'gatsby-theme-json-pages/src/components/dark-mode-toggle';
import SupradaLogo from './supradaLogo';
// import { Search } from 'gatsby-theme-json-pages/src/components/search';

import './header.css';

const Header = () => {
  const siteMetadata = useSiteMetadata();
  return (
    <header>
      <Link to="/">
        <div className="logo-container">
          <SupradaLogo height={40} width={40} className={'logo'} />
          <h3 className="logo-text">{siteMetadata.title}</h3>
        </div>
      </Link>
      <div className="controls">
        {/* <Search /> */}
        <DarkModeToggle />
      </div>
    </header>
  );
};

export default Header;
