import React from 'react';
import { graphql } from 'gatsby';
import JsonPage from '../components/json-page';

export const query = graphql`
  query($pageID: String!) {
    page: jsonNote(id: { eq: $pageID }) {
      title
      subTitle
      updated
      body
      id
    }
  }
`;

const JsonPageTemplate = ({ data }) => <JsonPage page={data.page} />;

export default JsonPageTemplate;
