import React from 'react';
import Helmet from 'react-helmet';
import Header from './header';
import useSiteMetadata from './use-site-metadata';

import './theme.css';
import './layout.css';
import './custom.css';

const Layout = ({ children }) => {
  const { description } = useSiteMetadata();

  return (
    <div className="layout">
      <Helmet>
        <meta name="description" content={description} />
        <script src="https://hypothes.is/embed.js" async />
      </Helmet>
      <Header />
      <div className="main-container">{children}</div>
    </div>
  );
};

export default Layout;
